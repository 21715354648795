import React from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import TimezoneSelection from '../TimezoneSelection/TimezoneSelection';
import ErrorNotification from '../ErrorNotification/ErrorNotification';
import SlotsNotification from '../SlotsNotification/SlotsNotification';
import WeekPicker from './WeekPicker/WeekPicker';
import WeekAvailability from './WeekAvailability/WeekAvailability';
import { WeeklyLayoutViewModel } from '../../ViewModel/weeklyLayoutViewModel/weeklyLayoutViewModel';
import { SlotsStatus } from '../../../../types/types';
import { DataHooks } from './constants';
import { classes } from './WeeklyLayout.st.css';

export interface WeeklyLayoutProps {
  viewModel: WeeklyLayoutViewModel;
  errorNotificationText: string;
}

const WeeklyLayout: React.FC<WeeklyLayoutProps> = ({
  viewModel: {
    bodyTitle,
    slotsStatus,
    slotsNotificationViewModel,
    timezoneSelectionViewModel,
    noAvailableSlotsViewModel,
    weekPickerViewModel,
    slotsPerDays,
  },
  errorNotificationText,
}) => {
  return (
    <div className={classes.root} data-hook={DataHooks.WeeklyLayout}>
      <SectionHeader
        title={bodyTitle}
        suffix={
          <TimezoneSelection
            viewModel={timezoneSelectionViewModel}
            dataHook={DataHooks.Timezone}
          />
        }
      />
      <ErrorNotification errorText={errorNotificationText} />
      <WeekPicker viewModel={weekPickerViewModel!} />
      {slotsStatus === SlotsStatus.AVAILABLE_SLOTS ? (
        <SlotsNotification viewModel={slotsNotificationViewModel} />
      ) : null}
      <WeekAvailability
        slotsPerDays={slotsPerDays!}
        slotsStatus={slotsStatus}
        noAvailableSlotsViewModel={noAvailableSlotsViewModel!}
      />
    </div>
  );
};

export default WeeklyLayout;
