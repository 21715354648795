export enum DataHooks {
  SlotsSelection = 'slots-selection',
  TimeSlotText = 'time-slot-text',
  ShowAllButton = 'show-all-button',
}

export enum SlotsSelectionDisplayMode {
  Stretch = 'stretch',
  Column = 'column',
}
