import React from 'react';
import { TimezoneSelectionViewModel } from '../../ViewModel/timezoneSelectionViewModel/timezoneSelectionViewModel';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import { classes, st } from './TimezoneSelection.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { TimezoneSelection as BaseTimezoneSelection } from '@wix/bookings-viewer-ui';

export type TimezoneSelectionProps = {
  viewModel?: TimezoneSelectionViewModel;
  dataHook?: string;
};

const TimezoneSelection: React.FC<TimezoneSelectionProps> = ({
  viewModel,
  dataHook,
}) => {
  const { onTimezoneSelected } = useCalendarActions();
  const { isMobile } = useEnvironment();

  return (
    <>
      {viewModel ? (
        <div className={st(classes.root, { isMobile })} data-hook={dataHook}>
          <BaseTimezoneSelection
            isMobile={isMobile}
            selectedTimezone={viewModel.selectedTimezone}
            timezoneOptions={viewModel.selectableTimezones}
            onTimezoneSelected={onTimezoneSelected}
            locale={viewModel.timezoneLocale}
            timezoneLabel={viewModel.timezoneLabel}
          />
        </div>
      ) : null}
    </>
  );
};

export default TimezoneSelection;
