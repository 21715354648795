import React from 'react';
import { classes } from './DailyLayout.st.css';
import TimePicker from './TimePicker/TimePicker';
import DatePicker from './DatePicker/DatePicker';
import SectionHeader from '../SectionHeader/SectionHeader';
import ErrorNotification from '../ErrorNotification/ErrorNotification';
import { DailyLayoutViewModel } from '../../ViewModel/dailyLayoutViewModel/dailyLayoutViewModel';
import { DataHooks } from './constants';

export interface DailyLayoutProps {
  viewModel: DailyLayoutViewModel;
  errorNotificationText: string;
}

const DailyLayout: React.FC<DailyLayoutProps> = ({
  viewModel: { bodyTitle, datePickerViewModel, timePickerViewModel },
  errorNotificationText,
}) => {
  return (
    <div data-hook={DataHooks.DailyLayout}>
      <SectionHeader title={bodyTitle} />
      <ErrorNotification errorText={errorNotificationText} />
      <div className={classes.root}>
        <DatePicker datePickerViewModel={datePickerViewModel!} />
        <TimePicker viewModel={timePickerViewModel!} />
      </div>
    </div>
  );
};

export default DailyLayout;
